<template>
    <div class="card">
        <div class="card-body">
            <!-- 帖子列表 -->
            <div v-for="post in posts.posts" :key="post.id">
                <div class="card single-post">
                    <div class="card-body">
                        {{ post.post }}
                        <button v-if="is_me" type="button" class="btn btn-danger btn-sm" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                            删除
                        </button>

                        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight"
                            aria-labelledby="offcanvasRightLabel">
                            <div class="offcanvas-header">
                                <h5 class="offcanvas-title" id="offcanvasRightLabel">
                                    🙏🏻别删🙏🏻
                                </h5>
                                <button type="button" class="btn-close" data-bs-dismiss="offcanvas"
                                    aria-label="Close"></button>
                            </div>
                            <div class="offcanvas-body">
                                <div>
                                    管理员🧑🏻想看看你发的什么，若真的想删除请联系管理员！！！
                                </div>
                                <div>
                                    <button type="button" class="btn btn-info" @click="copyQQ()"
                                        style="margin-top: 10px;">
                                        点击获取管理员QQ
                                    </button>
                                </div>
                                <div v-if="copy_success" class="pop_up">
                                    <span>我已经在你的粘贴板里面了！！！</span>
                                </div>
                                <div v-if="copy_fail" class="pop_up">
                                    <span>哦豁，你的浏览器好像不支持复制哦</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';


export default {
    name: "UserProfilePosts",
    props: {
        posts: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const route = useRoute();
        const store = useStore();
        const userId = route.params.userId;
        const is_me = computed(() => userId === store.state.user.id);

        let copy_success = ref(false);
        let copy_fail = ref(false);
        const copyQQ = () => {
            try {
                navigator.clipboard.writeText("2717788242");
                copy_success.value = true;
                setTimeout(() => {
                    copy_success.value = false;
                }, 1000);
            } catch (err) {
                console.error('复制失败：', err);
                copy_fail.value = true;
                setTimeout(() => {
                    copy_success.value = false;
                }, 1000);
            }
        };

        return {
            is_me,
            copy_success,
            copy_fail,
            copyQQ,
        }
    }
}
</script>


<style scoped>
.single-post {
    margin-bottom: 15px;
    font-size: 15px;
}

button {
    float: right;
}

.pop_up {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 15%;
    background-color: rgb(255, 243, 205);
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}
</style>
