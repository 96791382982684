<template>
    <div class="container">
        <div v-if="Popup" class="massage text-center">
            {{ massage }}
        </div>
        <div class="row">
            <div class="col-3">
                <div class="card" style="margin-top: 20px;">
                    <div class="card-body text-center">
                        <img class="img-fluid" :src="$store.state.user.photo" alt="" style="width: 100%;">
                        <hr>
                        <!-- Button trigger modal -->
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#photo">
                            更换头像
                        </button>

                        <!-- Modal -->
                        <div class="modal fade" id="photo" tabindex="-1" aria-labelledby="photoLabel"
                            aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5" id="photoLabel">
                                            请选择您喜欢的头像
                                        </h1>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <input v-model="photo" type="text" class="form-control" id="username"
                                            placeholder="由于未做图床,请复制您喜欢头像的完整URL,完成后刷新即可。">
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary"
                                            data-bs-dismiss="modal">不想更换了</button>
                                        <button type="button" data-bs-dismiss="modal" class="btn btn-primary"
                                            @click="update_profile_userphoto()">
                                            确认更换
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-9">
                <div class="card" style="margin-top: 20px;">
                    <div class="card-body">
                        <h4>个人资料</h4>
                        <hr>
                        <div class="profile-info text-center">
                            <div class="input-group mb-3">
                                <span class="input-group-text">用户名称</span>
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="username" placeholder="Username"
                                        v-model="username">
                                    <label for="username">username</label>
                                </div>
                            </div>
                            <div class="input-group mb-3">
                                <span class="input-group-text">个性签名</span>
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="signature" placeholder="Username"
                                        v-model="signature">
                                    <label for="signature">signature</label>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary"
                                @click="update_profile_username()">更新信息</button>
                        </div>
                    </div>
                </div>

                <div class="card" style="margin-top: 20px;">
                    <div class="card-body">
                        <h4>帐户信息</h4>
                        <hr>
                        <div class="row">
                            <div class="col-4">
                                <img src="@/assets/svg_icons/shield-lock-fill.svg" alt="">
                                &nbsp;
                                密码
                            </div>
                            <div class="col-4 text-center">已设置</div>
                            <div class="col-4">
                                <UserSettings>
                                    修改密码
                                </UserSettings>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-4">
                                <img src="@/assets/svg_icons/phone.svg" alt="">
                                &nbsp;手机号
                            </div>
                            <div class="col-4 text-center">未绑定</div>
                            <div class="col-4">
                                <UserSettings>
                                    绑定手机
                                </UserSettings>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-4">
                                <img src="@/assets/svg_icons/tencent-qq.svg" alt="">
                                &nbsp;
                                QQ
                            </div>
                            <div class="col-4 text-center">未绑定</div>
                            <div class="col-4">
                                <UserSettings>
                                    绑定QQ
                                </UserSettings>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-4">
                                <img src="@/assets/svg_icons/wechat.svg" alt="">
                                &nbsp;
                                微信
                            </div>
                            <div class="col-4 text-center">未绑定</div>
                            <div class="col-4">
                                <UserSettings>
                                    绑定微信
                                </UserSettings>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-4">
                                <img src="@/assets/svg_icons/bilibili.svg" alt="">
                                &nbsp;
                                BiliBili
                            </div>
                            <div class="col-4 text-center">
                                未绑定
                            </div>
                            <div class="col-4">
                                <UserSettings>
                                    绑定B站
                                </UserSettings>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-4">
                                <img src="@/assets/svg_icons/github.svg" alt="">
                                &nbsp;
                                GitHub
                            </div>
                            <div class="col-4 text-center">
                                未绑定
                            </div>
                            <div class="col-4">
                                <UserSettings>
                                    绑定GitHub
                                </UserSettings>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import $ from 'jquery';
import { useStore } from 'vuex';
import UserSettings from '@/components/UserSettings.vue';

export default {
    components: {
        UserSettings,
    },
    setup() {
        let username = ref('');
        let signature = ref('');
        let photo = ref('');
        const store = useStore();
        const userId = store.state.user.id;
        let massage = ref('');
        let Popup = ref(false);

        const update_profile_username = () => {
            if (username.value === '') {
                massage.value = "⚠您未填写用户名，未更新成功！";
                Popup.value = true;
                setTimeout(() => {
                    Popup.value = false;
                    massage.value = "";
                }, 1000);
            } else if (username.value === store.state.user.name) {
                massage.value = "⚠更新用户名与当前用户名相同，未更新成功！";
                Popup.value = true;
                setTimeout(() => {
                    Popup.value = false;
                    massage.value = "";
                }, 1000);
            } else {
                $.ajax({
                    url: "https://www.lycoding.fun/user/update/username/",
                    type: "post",
                    data: {
                        userId,
                        username: username.value,
                    },
                    headers: {
                        Authorization: "Bearer " + store.state.user.token,
                    },
                    success(resp) {
                        massage.value = resp;
                        Popup.value = true;
                        setTimeout(() => {
                            Popup.value = false;
                            massage.value = "";
                            username.value = "";
                            signature.value = "";
                        }, 1000);
                        window.location.reload();
                    },
                    error(resp) {
                        console.log("更新用户名错误：" + resp);
                    }
                })
            }
        };

        const update_profile_userphoto = () => {
            // console.log("调试" + userId);
            if (photo.value === '') {
                Popup.value = true;
                massage.value = "⚠您未选择图片，未更新成功！";
                setTimeout(() => {
                    Popup.value = false;
                    massage.value = "";
                }, 1000);
            } else {
                $.ajax({
                    url: "https://www.lycoding.fun/user/update/userphoto/",
                    type: "POST",
                    data: {
                        userId,
                        photo: photo.value,
                    },
                    headers: {
                        Authorization: "Bearer " + store.state.user.token,
                    },
                    success(resp) {
                        console.log(resp);
                        massage.value = "更换头像成功！若无法及时显示请刷新重试。"
                        Popup.value = true;
                        setTimeout(() => {
                            Popup.value = false;
                            massage.value = "";
                        }, 1000);
                        window.location.reload();
                    },
                    error(resp) {
                        console.log("更换头像错误：" + resp);
                    }
                });
            }
        }

        return {
            username,
            signature,
            photo,
            massage,
            Popup,
            update_profile_username,
            update_profile_userphoto,
        }
    }

}
</script>

<style scope>
.profile-info {
    width: 50%;
    margin: 0 auto;
}

.profile-info mb-3 {
    display: flex;
}

.massage {
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 20%;
    background-color: rgb(242, 206, 86);
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-16%);
    text-align: center;
    z-index: 10;
}
</style>