<template>
    <div class="card container">
        <div class="card-body">

            <div class="card container">
                <div class="card-body">
                    <span style="font-size: 40px; user-select: none;">
                        <img src="@/assets/svg_icons/snake.svg" alt="">
                    </span>
                    &nbsp;
                    欢迎大家来玩啊!
                </div>
            </div>

            <div class="row container">
                <div class="col-4 container ">
                    <div class="card container">
                        <div class="card-body match" @click="open_pk_match()">
                            <span style="font-size: 80px;">匹</span><span style="font-size: 40px;">配</span>
                        </div>
                    </div>
                </div>

                <div class="col-4 container">
                    <div class=" card container">
                        <div class="card-body bot" @click="open_pk_bot()">
                            <span style="font-size: 80px;">人</span><span style="font-size: 40px;">机</span>
                        </div>
                    </div>
                </div>

                <div class="col-4 container">
                    <div class=" card container">
                        <div class="card-body bot" @click="open_training()">
                            <span style="font-size: 80px;">训</span><span style="font-size: 40px;">练</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router/index';
import { useStore } from 'vuex';

export default {
    setup() {
        const store = useStore();

        const open_pk_match = () => {
            if (store.state.user.is_login) {
                router.push({
                    name: "pk_index",
                })
            } else {
                router.push({
                    name: 'user_account_login'
                });
            }
        }

        const open_pk_bot = () => {
            if (store.state.user.is_login) {
                router.push({
                    name: "pk_bot",
                })
            } else {
                router.push({
                    name: 'user_account_login'
                });
            }
        }

        const open_training = () => {
            window.location.href = "https://lycoding.fun/training/";
        }

        return {
            open_pk_match,
            open_pk_bot,
            open_training,
        }
    }
}
</script>

<style scoped>
.card {
    margin-top: 20px;
    width: 80%;
}

.card-body {
    text-align: center;
}

.match:hover {
    cursor: pointer;
}

.bot:hover {
    cursor: pointer;
}
</style>