<template>
    <div class="container">
        <div class="card">
            <div class="card-body">
                <div class="text-center">
                    <h1>
                        我要提意见，我要提bug (╯‵□′)╯炸弹！•••*～●
                    </h1>
                </div>
                <div class="alert alert-info" role="alert">
                    ❕意见栏:<br>
                    @Everyone:欢迎大家留下自己宝贵的建议，你们的建议是我最大的动力。
                </div>
                <div class="record-content-input">
                    <textarea placeholder="请输入意见内容......" v-model="content" style="overflow-y:auto"
                        class=" form-control text" aria-label="With textarea">
                    </textarea>

                    <div class="float-end" style="margin-top: 10px;">
                        <button v-if="content" @click="addcomment" type="button" class="btn btn-primary">
                            提交
                        </button>

                        <button v-if="!content" type="button" class="btn  btn-outline-primary" data-bs-toggle="modal"
                            data-bs-target="#toastModal">
                            提交
                        </button>
                        <div class="modal fade" id="toastModal" tabindex="-1" aria-labelledby="toastModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5" id="exampleModalLabel">📣</h1>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        ⚠你不可以发送空的内容哦！！！
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
                                            确定
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <BackToTop />

                <div class="record-content-list">
                    <div class="card" v-for="(comment, index) in comments" :key="index"
                        style="width: 100%; margin-top: 10px;">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-1 text-center">
                                    <img :src="comment.avatar" class="avatar" width="50" height="50" alt="" />
                                    <br>
                                    {{ comment.username }}
                                </div>
                                <div class="col-10 comment">{{ comment.commentContent }}</div>
                                <div class="col-1 text-center">
                                    <p v-if="isLove[index]" @click="clicklove(index)" :class="{ 'index': index }"
                                        style="font-size: 25px;">❤</p>
                                    <p v-if="!isLove[index]" @click="clicklove(index)" :class="{ 'index': index }"
                                        style="font-size: 25px;">🤍</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="Popup" class="massage text-center">
                    {{ success ? "点赞成功！！！" : "您已取消点赞！！！" }}
                </div>

            </div>
        </div>
    </div>

</template>
<script>
import { useStore } from "vuex";
import $ from "jquery";
import BackToTop from "@/components/BackToTop.vue";

export default {
    name: "RecordContent",
    components: {
        BackToTop,
    },

    data() {
        return {
            recordId: "",
            store: useStore(),
            comments: [],
            content: "",
            curindex: 1,
            isLove: [],
            Popup: false,
            success: false,
        };
    },

    methods: {
        getRecord() {
            let self = this;
            $.ajax({
                url: "https://www.lycoding.fun/comment/all/",
                type: "get",
                headers: {
                    Authorization: "Bearer " + this.store.state.user.token,
                },
                success(resp) {
                    console.log(resp);
                    self.comments = resp;
                    self.curindex = self.curindex + 1;
                },
            });
        },

        addcomment() {
            let self = this;
            if (this.content === "") {
                return;
            } else {
                $.ajax({
                    url: "https://www.lycoding.fun/comment/add/",
                    type: "get",
                    data: {
                        content: this.content,
                        userId: this.store.state.user.id,
                    },
                    headers: {
                        Authorization: "Bearer " + this.store.state.user.token,
                    },
                    success() {
                        self.content = '';
                        self.getRecord();
                    },
                });
            }
        },

        clicklove(index) {
            this.isLove[index] = !this.isLove[index];

            if (this.isLove[index] == false) {
                this.Popup = true;
                setTimeout(() => {
                    this.success = true;
                    this.Popup = false;
                }, 500)
                this.success = false;
            }

            if (this.isLove[index] == true) {
                this.Popup = true;
                setTimeout(() => {
                    this.success = false;
                    this.Popup = false;
                }, 500)
                this.success = true;
            }
        },
    },

    mounted() {
        this.getRecord();
    },
};
</script>

<style scoped>
.container {
    margin-top: 20px;
    width: 80%;
}

.avatar {
    margin-top: 10px;
    border-radius: 50%;
}

.record-content-list {
    margin-top: 60px;
}

p {
    cursor: pointer;
    user-select: none;
}

.massage {
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 5%;
    background-color: rgba(242, 206, 86, 0.5);
    position: fixed;
    top: 8%;
    left: 50%;
    transform: translateX(-16%);
    text-align: center;
    z-index: 10;
}
</style>