<template>
    <div class="full-screen">
        <ContentField class="notfound">
            <img src="@/assets/images/notfound.png" alt="404 Not Found">
            <div class="error-message">
                <h1>404</h1>
                <p>页面未找到</p>
            </div>
        </ContentField>
    </div>
</template>

<script>
import ContentField from "@/components/ContentField.vue";

export default {
    components: {
        ContentField,
    },
};
</script>

<style scoped>
.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;
}

.notfound {
    text-align: center;
}

.error-message {
    margin-top: 20px;
}

.error-message h1 {
    font-size: 100px;
    color: #d32f2f;
}

.error-message p {
    font-size: 24px;
    color: #333;
}
</style>