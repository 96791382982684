import { createRouter, createWebHistory } from 'vue-router';
import PKIndexView from '@/views/pk/PKIndexView.vue';
import RecordIndexView from '@/views/record/RecordIndexView.vue';
import RecordContentView from '@/views/record/RecordContentView.vue';
import RanklistIndexView from '@/views/ranklist/RanklistIndexView.vue';
import UserBotIndexView from '@/views/user/bot/UserBotIndexView.vue';
import NotFound from '@/views/error/NotFound.vue';
import UserAccountLoginView from '@/views/user/account/UserAccountLoginView.vue';
import UserAccountRegisterView from '@/views/user/account/UserAccountRegisterView.vue';
import UserListView from '@/views/chatroom/UserListView.vue';
import UserProfileView from '@/views/chatroom/UserProfileView.vue';
import TalkRoomView from '@/views/chatroom/TalkRoomView.vue';
import PKHomeView from '@/views/pk/PKHomeView.vue';
import BotPkIndexView from '@/views/pk/BotPkIndexView.vue';
import IndexView from '@/views/index/IndexView.vue';
import ChatGPT from '@/views/chatgpt/ChatGPT.vue';
import ProfileSettings from '@/views/profilesettings/ProfileSettings.vue';
import ChatRoomOne from '@/views/chatroom/ChatRoomOne.vue';
import ChatRoomTwo from '@/views/chatroom/ChatRoomTwo.vue';
import CommentView from '@/views/comment/CommentView.vue';
import store from '../store/index';
// import transitionExtend from './transition-extend';

const routes = [
  {
    path: '/',
    name: 'home',
    component: IndexView,
  },
  {
    path: '/user/account/login/',
    name: 'user_account_login',
    component: UserAccountLoginView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/user/account/register/',
    name: 'user_account_register',
    component: UserAccountRegisterView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/userlist/',
    name: 'userlist_index',
    component: UserListView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/userprofile/:userId/',
    name: 'userprofile',
    component: UserProfileView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/pkhome/',
    name: 'pk_home',
    component: PKHomeView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/pk/',
    name: 'pk_index',
    component: PKIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/botpk/',
    name: 'pk_bot',
    component: BotPkIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/record/',
    name: 'record_index',
    component: RecordIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/record/:recordId/',
    name: 'record_content',
    component: RecordContentView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/ranklist/',
    name: 'ranklist_index',
    component: RanklistIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/user/bot/',
    name: 'user_bot_index',
    component: UserBotIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/talkroom/:userId/',
    name: 'talkroom',
    component: TalkRoomView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/chatroomone/:userId/',
    name: 'chatroomone',
    component: ChatRoomOne,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/chatroomtwo/:userId/',
    name: 'chatroomtwo',
    component: ChatRoomTwo,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/chatgpt/',
    name: 'chatgpt',
    component: ChatGPT,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/comment/',
    name: 'comment',
    component: CommentView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/profilesettings/',
    name: 'profilesettings',
    component: ProfileSettings,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/404/',
    name: '404_index',
    component: NotFound,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404/' // 重定向到404页面
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.requestAuth && !store.state.user.is_login) {
    next({ name: "user_account_login" });
  } else {
    next()
  }
});

// router = transitionExtend(router);

export default router
