<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container">
      <!-- <a class="navbar-brand" href="/">King of Bots</a> -->
      <router-link class="navbar-brand" :to="{ name: 'home' }">智能对抗系统</router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
        aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link :class="route_name == 'pk_home' ? 'nav-link active' : 'nav-link'" :to="{ name: 'pk_home' }">
              <img src="@/assets/svg_icons/对战.svg" alt="">
              PK对战
            </router-link>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link" href="/record/">对局列表</a> -->
            <router-link :class="route_name == 'record_index' ? 'nav-link active' : 'nav-link'"
              :to="{ name: 'record_index' }">
              <img src="@/assets/svg_icons/列表.svg" alt="">
              对局列表
            </router-link>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link" href="/ranklist/">排行榜</a> -->
            <router-link :class="route_name == 'ranklist_index' ? 'nav-link active' : 'nav-link'"
              :to="{ name: 'ranklist_index' }">
              <img src="@/assets/svg_icons/排行榜.svg" alt="">
              排行榜
            </router-link>
          </li>

          <li class="nav-item" v-if="!$store.state.user.is_login">
            <router-link class="nav-link" :to="{ name: 'user_account_login' }">
              <img src="@/assets/svg_icons/聊天室.svg" alt="">
              聊天室
            </router-link>
          </li>
          <li class="nav-item" v-else>
            <router-link :class="route_name == 'talkroom' ? 'nav-link active' : 'nav-link'"
              :to="{ name: 'talkroom', params: { userId: $store.state.user.id } }">
              <img src="@/assets/svg_icons/聊天室.svg" alt="">
              聊天室
            </router-link>
          </li>

          <li class="nav-item">
            <router-link :class="route_name == 'chatgpt' ? 'nav-link active' : 'nav-link'" :to="{ name: 'chatgpt' }">
              <img src="@/assets/svg_icons/Chatgpt.svg" alt="">
              ChatGPT
            </router-link>
          </li>

          <li class="nav-item">
            <router-link :class="route_name == 'comment' ? 'nav-link active' : 'nav-link'" :to="{ name: 'comment' }">
              <img src="@/assets/svg_icons/comment.svg" alt="">
              意见栏
            </router-link>
          </li>
        </ul>

        <ul class="navbar-nav " v-if="$store.state.user.is_login">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <img class="rounded-circle" style="height: 25px;" :src="$store.state.user.photo" alt="">
              {{ $store.state.user.username }}
            </a>
            <ul class="dropdown-menu">
              <li>
                <!-- <a class="dropdown-item" href="/user/bot">我的Bot</a> -->
                <router-link class="dropdown-item" :to="{ name: 'user_bot_index' }">
                  <img src="@/assets/svg_icons/bx-bot.svg" alt="">
                  我的Bot
                </router-link>
              </li>

              <li>
                <router-link class="dropdown-item"
                  :to="{ name: 'userprofile', params: { userId: $store.state.user.id } }">
                  <img src="@/assets/svg_icons/myspace.svg" alt="">
                  我的空间
                </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{ name: 'userlist_index' }">
                  <img src="@/assets/svg_icons/userlist.svg" alt="">
                  好友列表
                </router-link>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <router-link class="dropdown-item" :to="{ name: 'profilesettings' }">
                  <img src="@/assets/svg_icons/setting.svg" alt="">
                  设置
                </router-link>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li><a class="dropdown-item" href="#" @click="logout">
                  <img src="@/assets/svg_icons/exit.svg" alt="">
                  退出
                </a></li>
            </ul>
          </li>
        </ul>

        <ul class="navbar-nav " v-else-if="!$store.state.user.pulling_info">
          <li class="nav-item ">
            <router-link class="nav-link" :to="{ name: 'user_account_login' }" role="button">
              登录
            </router-link>
          </li>
          <li class="nav-item ">
            <router-link class="nav-link" :to="{ name: 'user_account_register' }" role="button">
              注册
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'NavBar',
  setup() {
    const route = useRoute();
    let route_name = computed(() => route.name);

    const store = useStore();
    const logout = () => {
      store.dispatch("logout");
    }

    return {
      route_name,
      logout,
    }
  }
}
</script>

<style scoped></style>