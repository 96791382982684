<template>
  <NavBar />
  <FooterView class="footer-view" />
  <router-view />
  <!-- <router-view v-slot="{ Component }">
    <transition :name="$router.customRouterData.transitionName">
      <component :is="Component" />
    </transition>
  </router-view> -->
</template>

<script>
import NavBar from './components/NavBar.vue';
import FooterView from './components/FooterView.vue';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
// import $ from "jquery";

export default {
  components: {
    NavBar,
    FooterView,
  },
  setup() {
    // $.ajax({
    //   url:"http://localhost:3000/user/account/info/",
    //   type:"get",
    //   headers:{
    //     Authorization:"Bearer " + "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJmMWUzOTgwM2RlYjY0MDMwOTZhZDk4YzIwOTkyMzQ1NCIsInN1YiI6IjEiLCJpc3MiOiJzZyIsImlhdCI6MTcwNDk4MzgzNSwiZXhwIjoxNzA2MTkzNDM1fQ.633mIzcMcGEwPKq5bWOAhvwdnVG3YymXsjHEUx3l2L8"
    //   },
    //   success(resp){
    //     console.log(resp);
    //   },
    //   error(resp){
    //     console.log(resp);
    //   }
    // });

    // $.ajax({
    //   url:"http://localhost:3000/user/account/register/",
    //   type:"post",
    //   data:{
    //     username:'LY1',
    //     password:"a",
    //     confirmedPassword:"a",
    //   },
    //   success(resp){
    //     console.log(resp);
    //   },
    //   error(resp){
    //     console.log(resp);
    //   }
    // });
  }
}
</script>

<style>
body {
  background-image: url("@/assets/images/background1.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.footer-view {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
}

/* .anime-enter-active,
.anime-leave-active {
  transition: all 1s;
}

.anime-leave-from {
  transform: translatex(0);
}

.anime-leave-to {
  transform: translatex(-100%);
}

.anime-enter-from {
  transform: translatex(100%);
}

.anime-enter-to {
  transform: translatex(0);
} */
</style>
