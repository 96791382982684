<template>
    <div class="container">
        <div class="row">
            <div class="col-4">
                <div class="card text-center">
                    <img src="@/assets/images/robot.png" class="card-img-top img-fluid" alt="">
                    <div class="card-body">
                        <h5 class="card-title">Bot交流室</h5>
                        <p class="card-text">
                            这是一个名为 "Bot交流室"
                            的聊天室，旨在为用户提供一个自由交流和沟通的空间。用户可以互相交流人工智能助手方面的内容。旨在促进用户之间的互动和沟通，从而形成一个活跃的社区。
                        </p>
                        <button class="btn btn-primary" @click="open_chatroom_one()">
                            Go Here
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card text-center">
                    <img src="@/assets/images/代码.png" class="card-img-top" alt="">
                    <div class="card-body">
                        <h5 class="card-title">Bot执行代码交流室</h5>
                        <p class="card-text">
                            旨在为用户提供一个分享和交流Bot执行代码的地方。用户可以分享自己的代码,讨论和优化彼此的代码,从而提高自己的编程能力。促进用户之间的学习和交流，从而形成一个积极的学习社区。
                        </p>
                        <button class="btn btn-primary" @click="open_chatroom_two()">
                            Go Here
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card text-center">
                    <img src="@/assets/images/地图设计.png" class="card-img-top" alt="">
                    <div class="card-body">
                        <h5 class="card-title">地图设计交流室</h5>
                        <p class="card-text">
                            旨在为用户提供一个分享和交流地图设计的地方。用户可以分享自己的地图设计作品，讨论和优化彼此的地图设计，从而提高自己的设计能力。
                        </p>
                        <button class="btn btn-primary" @click="open_chatroom_one()">
                            Go Here
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-4">
                <div class="card text-center">
                    <img src="@/assets/images/匹配.png" class="card-img-top" alt="">
                    <div class="card-body">
                        <h5 class="card-title">匹配机制交流室</h5>
                        <p class="card-text">
                            旨在为用户提供一个分享和交流游戏匹配机制的地方。用户可以分享自己的游戏匹配机制设计，讨论和优化彼此的匹配机制设计。促进用户之间的学习和交流。
                        </p>
                        <button class="btn btn-primary" @click="open_chatroom_one()">
                            Go Here
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card text-center">
                    <img src="@/assets/images/开发者.png" class="card-img-top" alt="">
                    <div class="card-body">
                        <h5 class="card-title">开发交流</h5>
                        <p class="card-text">
                            旨在为用户提供一个分享和交流开发经验和技术的地方。用户可以分享自己的开发经历，讨论和解决各种技术问题，从而提高自己的开发能力。
                        </p>
                        <button class="btn btn-primary" @click="open_chatroom_two()">
                            Go Here
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card text-center">
                    <img src="@/assets/images/qustion.png" class="card-img-top" alt="">
                    <div class="card-body">
                        <h5 class="card-title">探寻中。。。</h5>
                        <p class="card-text">
                            更多聊天室敬请期待，或请联系管理员新建聊天室，切忌遵纪守法，聊天内容请勿涉及政治、色情等敏感信息......争做中国好青年，拒绝黄赌毒。你一定会成为梦想的人。
                        </p>
                        <button class="btn btn-primary">
                            敬请期待...
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router/index';
import { useStore } from 'vuex';
export default {
    setup() {
        const store = useStore();
        const open_chatroom_one = () => {
            router.push({ name: 'chatroomone', params: { userId: store.state.user.id } })
        }

        const open_chatroom_two = () => {
            router.push({ name: 'chatroomtwo', params: { userId: store.state.user.id } })
        }

        return {
            open_chatroom_one,
            open_chatroom_two,
        }
    }
}
</script>

<style scoped>
.row {
    margin: 20px 40px 40px 20px;
}

.card-body {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
}

.card-body>p:hover {
    overflow: auto;
    white-space: normal;
}

p {
    font-size: 12px;
}
</style>