<template>
    <div class="code-bot-info" data-bs-toggle="modal" data-bs-target="#code-bot-info">
        <img src="@/assets/svg_icons/lightbulb.svg" alt="light">
        <slot></slot>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="code-bot-info" tabindex="-1">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Bot代码入门教程</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="bot-info-title" class="form-label">关于Bot</label>
                        <textarea v-model="botinfo.title" class="form-control" id="bot-info-title" rows="2" readonly>
                        </textarea>
                    </div>

                    <div class="mb-3">
                        <label for="bot-info-description" class="form-label">需实现的关键接口和方法</label>
                        <VAceEditor readonly v-model:value="botinfo.description" @init="editorInit" lang="c_cpp"
                            theme="textmate" style="height: 100px" :options="{
                                fontSize: 18, //设置字号
                                tabSize: 4, // 标签大小
                                showPrintMargin: false, //去除编辑器里的竖线
                                highlightActiveLine: true, //高亮
                                showLineNumbers: false, //去除行号
                                showFoldWidgets: false, //去除折叠
                            }" />
                    </div>

                    <div class="mb-3">
                        <div v-if="copy_success" class="pop_up">
                            <span>复制成功啦，快去试试无敌的Bot吧！</span>
                        </div>
                        <div v-if="copy_fail" class="pop_up">
                            <span>复制失败啦，请重试，或者当前浏览器暂不支持！</span>
                        </div>

                        <label for="bot-info-code" class="form-label">示例代码</label>
                        <button class="btn btn-primary btn-sm float-end" @click="copyCode">一键复制</button>
                        <VAceEditor readonly v-model:value="botinfo.content" @init="editorInit" lang="c_cpp"
                            theme="textmate" style="height: 300px" :options="{
                                enableBasicAutocompletion: true, //启用基本自动完成
                                enableSnippets: true, // 启用代码段
                                enableLiveAutocompletion: true, // 启用实时自动完成
                                fontSize: 18, //设置字号
                                tabSize: 4, // 标签大小
                                showPrintMargin: false, //去除编辑器里的竖线
                                highlightActiveLine: true,
                            }" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">我已知晓</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref } from 'vue';
import { VAceEditor } from 'vue3-ace-editor';
import ace from 'ace-builds';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/ext-language_tools';
import { codeExample } from '@/assets/scripts/ExampleCode';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

export default {
    components: {
        VAceEditor
    },

    setup() {
        ace.config.set(
            "basePath",
            "https://cdn.jsdelivr.net/npm/ace-builds@" +
            require('ace-builds').version +
            "/src-noconflict/")

        const botinfo = reactive({
            title: `你需要实现BotInterface接口的nextMove方法，蛇的每一次移动都会调用nextMove方法，
来获得下一步的方向。 nextMove方法的返回值为0、1、2、3，分别代表上、右、下、左。`,
            description: `package com.kob.botrunningsystem.utils;

public interface BotInterface {
    Integer nextMove(String input);
}`,
            content: codeExample,
        });

        let copy_success = ref(false);
        let copy_fail = ref(false);
        const copyCode = () => {
            try {
                navigator.clipboard.writeText(botinfo.content);
                copy_success.value = true;
                setTimeout(() => {
                    copy_success.value = false;
                }, 1000);
            } catch (err) {
                console.error('复制失败：', err);
                copy_fail.value = true;
                setTimeout(() => {
                    copy_success.value = false;
                }, 1000);
            }
        };

        return {
            botinfo,
            copy_success,
            copy_fail,
            copyCode,
        }
    }
};
</script>

<style scoped>
div.code-bot-info {
    margin: 10px auto;
    background-color: transparent;
    border: none;
    display: inline;
}

div.code-bot-info:hover {
    cursor: pointer;
    color: #007ACC;
}

.pop_up {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 11%;
    background-color: rgba(255, 243, 205, 1);
    position: absolute;
    top: 7%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}
</style>