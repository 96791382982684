<template>
    <div class="footer">
        &copy; 2024 LYcoding 版权所有 | <a href="https://beian.miit.gov.cn">苏ICP备2024094389号-1</a>
    </div>
</template>

<script>
export default {
};
</script>

<style scoped>
.footer {
    text-align: center;
    font-size: 12px;
    color: rgb(139, 140, 140);
}
</style>