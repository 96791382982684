<template>
    <div class="card edit-field">
        <div class="card-body">
            <div class="form-floating">
                <textarea v-model="content" class="form-control" placeholder="Leave a comment here" id="edit-post"
                    style="height: 100px"></textarea>
                <label for="floatingTextarea" color="grey">Comment</label>
            </div>
            <button @click="post_a_post" type="button" class="btn btn-primary btn-sm">发帖</button>
        </div>
        <div v-if="showPopup" class="pop_up">
            <span>⚠你不可以发送空帖子！！！</span>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    name: "UserProfileWrite",
    setup(props, context) {
        let content = ref('');

        let showPopup = ref(false);
        const post_a_post = () => {
            if (content.value === "") {
                showPopup.value = true;
                setTimeout(() => {
                    showPopup.value = false;
                }, 1000);
                return;
            }
            context.emit('post_a_post', content.value);
            content.value = "";
        }

        return {
            content,
            showPopup,
            post_a_post,
        }
    }
}
</script>

<style scoped>
.edit-field {
    margin-top: 20px;
}

button {
    margin-top: 10px;
}

.pop_up {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 70%;
    background-color: rgb(255, 243, 205);
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: red;
}
</style>
