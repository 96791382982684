<template>
    <div class="back-to-top">
        <button @click="scrollToTop" v-show="showButton">
            <svg t="1715401144961" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                p-id="4264" width="32" height="32">
                <path
                    d="M170.666667 128h682.666666a42.666667 42.666667 0 0 1 0 85.333333H170.666667a42.666667 42.666667 0 1 1 0-85.333333z m298.666666 276.693333l-225.834666 225.834667a42.666667 42.666667 0 1 1-60.330667-60.330667l301.696-301.696a42.666667 42.666667 0 0 1 60.330667 0l301.696 301.696a42.666667 42.666667 0 0 1-60.330667 60.330667L554.666667 398.634667V896a42.666667 42.666667 0 0 1-85.333334 0V404.693333z"
                    fill="#444444" p-id="4265"></path>
            </svg>
        </button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showButton: false,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.showButton = window.pageYOffset > 300;
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
    },
};
</script>

<style scoped>
.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

button {
    background-color: #fff;
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    transition: all 0.3s ease;
}

button:hover {
    background-color: #f5f5f5;
}
</style>
