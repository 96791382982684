<template>
    <!-- Button trigger modal -->
    <button type="button" class="btn float-end btn btn-outline-success" data-bs-toggle="modal"
        data-bs-target="#settings">
        <slot></slot>
    </button>

    <!-- Modal -->
    <div class="modal fade" id="settings" tabindex="-1" aria-labelledby="settingsLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h3>设置</h3>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <input type="text" class="form-control" placeholder="开发中....">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">关闭</button>
                    <button type="button" data-bs-dismiss="modal" class="btn btn-primary">
                        确认
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style></style>