<template>
    <div class="main">
        <div class="content" id="content" ref="contentRef">
            <div v-for="(item, index) in messageList" :key="index">
                <!-- 用户消息 -->
                <div class="visitor d-flex flex-row-reverse mb-3" v-if="item.user === 'visitor'">
                    <img src="@/assets/images/visitor.png" alt="User" class="rounded-circle me-2" width="42"
                        height="42">
                    <div class="bubble bg-light rounded px-3 py-2">
                        {{ item.content }}
                    </div>
                </div>
                <!-- 机器人消息 -->
                <div class="robot d-flex mb-3" v-if="item.user === 'robot'">
                    <img src="@/assets/images/robot.png" alt="Robot" class="rounded-circle ms-2" width="42" height="42">
                    <div class="bubble bg-primary text-white rounded px-3 py-2">
                        {{ item.content }}
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部输入框 -->
        <div class="bottom">
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="请输入..." v-model="sendData">
                <button class="btn btn-primary" type="button" :disabled="sendFlag" @click="sendMessage">发送</button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
    setup() {
        const sendData = ref('');
        const messageList = ref([]);
        const sendFlag = ref(false);
        const contentRef = ref(null);

        // 显示Toast提示
        const showToast = (message) => {
            const robotResponse = { user: 'robot', content: message || '正在思考...' };
            messageList.value.push(robotResponse);
        };

        // 发送消息到服务器
        const sendMessage = async () => {
            if (!sendData.value.trim()) {
                showToast('你想让我回答什么呢？请输入您的问题');
                return;
            }

            sendFlag.value = true;
            const newItem = { user: 'visitor', content: sendData.value };
            messageList.value.push(newItem);
            sendData.value = ''; // 清空输入框

            try {
                const response = await fetch('https://api.openai.com/v1/chat/completions', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer api_key`
                    },
                    body: JSON.stringify({
                        model: 'gpt-3.5-turbo',
                        stream: true,
                        messages: [{ role: "user", content: newItem.content }],
                    })
                });

                alert(response.status);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                const robotResponse = { user: 'robot', content: data.message || '正在思考...' };
                messageList.value.push(robotResponse);
                sendFlag.value = false; // 请求完成
            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
                showToast('请求失败，请稍后再试。或检查代理');
                sendFlag.value = false; // 请求失败也要重置状态
            }
        };

        // 自动滚动到底部
        onMounted(() => {
            scrollToBottom();
            window.addEventListener('resize', scrollToBottom);
        });

        onUnmounted(() => {
            window.removeEventListener('resize', scrollToBottom);
        });

        const scrollToBottom = () => {
            if (contentRef.value) {
                contentRef.value.scrollTop = contentRef.value.scrollHeight;
            }
        };

        return {
            sendData,
            messageList,
            sendFlag,
            sendMessage,
            contentRef,
        };
    },
};
</script>

<style scoped>
.content {
    margin: 0 auto;
    width: 34%;
    height: 78vh;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    background: #f3f3d9;
}

.bottom {
    display: flex;
    padding: 10px 10px 35px;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: space-between;
    background: rgb(244, 244, 244, 0.1);
    position: fixed;
    max-width: 520px;
    margin: 0 auto;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
    z-index: 999;
}
</style>