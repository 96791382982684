<template>
    <ContentBase>
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <h2>部分信息展示</h2>
                    <div id="carouselDark" class="carousel carousel-dark slide">
                        <div class="carousel-indicators">
                            <button type="button" data-bs-target="#carouselDark" data-bs-slide-to="0" class="active"
                                aria-current="true" aria-label="Slide 1">
                            </button>
                            <button type="button" data-bs-target="#carouselDark" data-bs-slide-to="1"
                                aria-label="Slide 2">
                            </button>
                            <button type="button" data-bs-target="#carouselDark" data-bs-slide-to="2"
                                aria-label="Slide 3">
                            </button>
                            <button type="button" data-bs-target="#carouselDark" data-bs-slide-to="3"
                                aria-label="Slide 4">
                            </button>
                            <button type="button" data-bs-target="#carouselDark" data-bs-slide-to="4"
                                aria-label="Slide 5">
                            </button>
                            <button type="button" data-bs-target="#carouselDark" data-bs-slide-to="5"
                                aria-label="Slide 6">
                            </button>
                            <button type="button" data-bs-target="#carouselDark" data-bs-slide-to="6"
                                aria-label="Slide 7">
                            </button>
                            <button type="button" data-bs-target="#carouselDark" data-bs-slide-to="7"
                                aria-label="Slide 8">
                            </button>
                            <button type="button" data-bs-target="#carouselDark" data-bs-slide-to="8"
                                aria-label="Slide 9">
                            </button>
                        </div>
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="10000">
                                <img src="@/assets/script_imgs/pkhome.png" class="d-block" alt="...">
                                <div class="carousel-caption d-none d-md-block">
                                    <p>用户在这里可选择对局模式，对局支持人人，人机，机机大战。</p>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="2000">
                                <img src="@/assets/script_imgs/userpk.png" class="d-block" alt="...">
                                <div class="carousel-caption d-none d-md-block" style="bottom: 10px; color: aliceblue;">
                                    <p>用户匹配界面</p>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img src="@/assets/script_imgs/botpk.png" class="d-block" alt="...">
                                <div class="carousel-caption d-none d-md-block" style="color: aliceblue;">
                                    <p>涉及Bot的对局,用户可点击上方的“参考教程”去创建Bot</p>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img src="@/assets/script_imgs/pkindex.png" class="d-block" alt="...">
                                <div class="carousel-caption d-none d-md-block">
                                    <p>对局界面</p>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img src="@/assets/script_imgs/record.png" class="d-block" alt="...">
                                <div class="carousel-caption d-none d-md-block">
                                    <p>对局回放界面</p>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img src="@/assets/script_imgs/ranklist.png" class="d-block" alt="...">
                                <div class="carousel-caption d-none d-md-block">
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img src="@/assets/script_imgs/talkroom.png" class="d-block" alt="...">
                            </div>
                            <div class="carousel-item">
                                <img src="@/assets/script_imgs/comment.png" class="d-block" alt="...">
                                <div class="carousel-caption d-none d-md-block">
                                    <p>用户可在此提出意见</p>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img src="@/assets/script_imgs/userprofile.png" class="d-block" alt="...">
                                <div class="carousel-caption d-none d-md-block">
                                    <p>用户的个人空间</p>
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselDark"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselDark"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <h2>项目介绍</h2>
                    <p>本项目是基于SpringBoot+vue3的前后端分离项目，所用到的技术栈包括但不限于，如下：</p>
                    <div style="width: 60%; margin:0 auto;">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>技术栈</th>
                                    <th>描述</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>SpringBoot</td>
                                    <td>容器+MVC框架</td>
                                </tr>
                                <tr>
                                    <td>Mysql</td>
                                    <td>关系型数据库</td>
                                </tr>
                                <tr>
                                    <td>JWT</td>
                                    <td>登录验证</td>
                                </tr>
                                <tr>
                                    <td>SpringSecurity</td>
                                    <td>验证和授权</td>
                                </tr>
                                <tr>
                                    <td>Lombok</td>
                                    <td>简化对象封装工具</td>
                                </tr>
                                <tr>
                                    <td>MyBatisPlus</td>
                                    <td>ORM框架</td>
                                </tr>
                                <tr>
                                    <td>MicroService(SpringCloud)</td>
                                    <td>微服务</td>
                                </tr>
                                <tr>
                                    <td>Docker</td>
                                    <td>虚拟化服务</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <h2>游戏规则</h2>
                    <p>确切地说，这并不是贪吃蛇。 与传统单人贪吃蛇不同的是，本贪吃蛇可以双人对战，人机大战，机机大战，每回合玩家同时做出决策控制自己的蛇。涉及Bot的对局需要执行代码,可去我的Bot建立自己的Bot。
                    </p>
                    <p>玩家在 13×14 的网格中操纵一条蛇(蛇是一系列坐标构成的有限不重复有顺序的序列，序列中相邻坐标均相邻，即两坐标的 x 轴坐标或 y 轴坐标相同， 坐标从0开始，x 轴代表行，y
                        轴代表列，序列中最后一个坐标代表蛇头)，玩家只能控制蛇头的朝向(上、下、左、右)来控制蛇。蛇以恒定的速度
                        前进(前进即为序列末尾插入蛇头指向方向下一格坐标，并删除序列头坐标)。蛇的初始位置在网格中的左下角(地图位置[11, 1])与右上角(地图 位置[1, 12])，初始长度为 1
                        格。与传统贪吃蛇不同，本游戏在网格中并没有豆子，但蛇会自动长大(长大即为不删除序列头坐标的前进)，前10回 合每回合长度增加 1，从第 11 回合开始，每 3 回合长度增加 1。
                    </p>
                    <p>地图为13×14的网格，由1×1的草地与障碍物构成。</p>
                    <p>蛇头在网格外、障碍物、自己蛇的身体(即序列重复)、对方蛇的身体(即与对方序列有相同坐标)，或非法操作均判定为死亡。任何一条蛇死亡时，
                        游戏结束。若蛇同时死亡，判定为平局，否则先死的一方输，另一方赢。</p>
                </div>
            </div>
        </div>
    </ContentBase>

</template>

<script>
import ContentBase from '@/components/ContentBase.vue';

export default {
    components: {
        ContentBase,
    },
};
</script>

<style scoped>
.card {
    margin-bottom: 20px;
}

.carousel-item img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
</style>