<template>
    <div class="row">
        <div class="col-9">
            <div class="text-center" style="font-size: 30px;">精彩瞬间</div>
            <PlayGround :key="gamekey" />
        </div>
        <div class="col-3 text-center">
            <button type="button" class="btn btn-success btn-lg" @click="restartplayback()">
                回看
            </button>
            <button type="button" class="btn btn-success btn-lg like-button" @click="toggleLike()"
                :class="{ active: liked }">
                {{ liked ? "取消点赞" : "点赞" }}
                <span v-if="liked">❤️</span>
            </button>
            <button type="button" class="btn btn-success btn-lg" @click="open_pk_record()">
                返回
            </button>
            <div class="card" v-if="record_id">
                <div class="card-body">
                    <div class="text-center" style="font-size: 20px;">对局信息</div>
                    <div class="row">
                        <div class="col-5 text-center">
                            <span style="color: blue; font-size: 18px;">蓝色方</span>
                            <div><img class="img-fluid" :src="record.a_photo" alt="a_photo"></div>
                            <div style="margin-top: 10%;">{{ record.a_username }}</div>
                        </div>
                        <div class="col-2 vs">
                            VS
                        </div>
                        <div class="col-5 text-center">
                            <span style="color: red; font-size: 18px;">红色方</span>
                            <div><img class="img-fluid" :src="record.b_photo" alt="b_photo"></div>
                            <div style="margin-top: 10%;">{{ record.b_username }}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center record-info">
                            <div v-if="record.result === 'A胜'">
                                <span style="font-size: 40px;">🏆</span>
                                &nbsp;
                                <span style="color: blue; font-style: italic;">蓝色方</span>
                                &nbsp;
                                <span style="color: yellow; font-size: 30px;">胜利</span>
                            </div>
                            <div v-else-if="record.result === 'B胜'">
                                <span style="font-size: 40px;">🏆</span>
                                &nbsp;
                                <span style="color: red; font-style: italic;">红色方</span>
                                &nbsp;
                                <span style="color: yellow; font-size: 30px;">胜利</span>
                            </div>
                            <div v-else>
                                <span>平局</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PlayGround from "@/components/PlayGround.vue";
import router from "@/router";
import { ref } from "vue";
import { useStore } from 'vuex';
import { useRoute } from "vue-router";
import $ from "jquery";

export default {
    components: {
        PlayGround,
    },

    setup() {
        let gamekey = ref(0);

        const restartplayback = () => {
            gamekey.value++;
        };

        const open_pk_record = () => {
            router.push({ name: "record_index" });
        };

        const route = useRoute();
        const store = useStore();
        const record = ref({});
        const record_id = route.params.recordId;
        const pull_current_record = () => {
            $.ajax({
                url: "https://www.lycoding.fun/api/record/getlistid/",
                type: "get",
                data: {
                    record_id,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    record.value = resp;
                },
                error(resp) {
                    console.log("error" + resp);
                },
            })
        };

        pull_current_record();

        const liked = ref(false);
        const toggleLike = () => {
            liked.value = !liked.value;
        };

        return {
            gamekey,
            record,
            record_id,
            liked,
            restartplayback,
            open_pk_record,
            toggleLike,
        }
    }
};
</script>

<style scoped>
.col-3 {
    transform: translateX(-50%);
}

button {
    margin: 10px;
}

.card {
    background-color: transparent;
    border: none;
}

img {
    margin-top: 10%;
    border-radius: 50%;
    width: 20vh;
    user-select: none;
}

.vs {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-style: italic;
    color: #FACC15;
    user-select: none;
}

.record-info {
    margin-top: 5%;
    font-size: 20px;
    color: aliceblue;
    user-select: none;
}

.like-button {
    transition: transform 0.3s ease;
    position: relative;
}

.like-button.active {
    animation: likeAnimation 0.3s ease;
}

.like-button:active {
    transform: scale(1.1);
}

.like-button span {
    margin-left: 5px;
    color: red;
    animation: heartBeat 0.5s infinite;
}

@keyframes likeAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes heartBeat {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.2);
    }

    50% {
        transform: scale(1);
    }

    75% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}
</style>