<template>
    <ContentField v-if="!$store.state.user.pulling_info">
        <div class="row justify-content-md-center">
            <div class="col-3">
                <form @submit.prevent="login">
                    <div class="mb-3">
                        <label for="username" class="form-label">用户名</label>
                        <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">密码</label>
                        <input v-model="password" type="password" class="form-control" id="password"
                            placeholder="请输入密码">
                    </div>
                    <div class="error-message">{{ error_message }}</div>
                    <button type="submit" class="btn btn-primary">登录</button>
                </form>
                <div class="mt-2 text-primary">
                    <span>验证码登录</span>
                    <span class="float-end">
                        <router-link :to="{ name: 'user_account_register' }" class="text-decoration-none" role="button">
                            暂未注册？
                        </router-link>
                    </span>
                </div>
            </div>
            <div class="text-center svg-icons">
                <img src="@/assets/svg_icons/tencent-qq.svg" alt="qq" width="32" height="32" class="qq"
                    @click="ClickshowPopup">
                <img src="@/assets/svg_icons/wechat.svg" alt="wx" width="32" height="32" class="wx"
                    @click="ClickshowPopup">
                <img src="@/assets/svg_icons/github.svg" alt="github" width="32" height="32" class="github"
                    @click="ClickshowPopup">
                <img src="@/assets/svg_icons/twitter.svg" alt="twitter" width="32" height="32" class="twitter"
                    @click="ClickshowPopup">
                <div v-if="showPopup" class="pop_up">
                    <p>暂时未实现该功能哦,请在等等把❤❤❤</p>
                </div>
            </div>
            <div class="mt-3 text-muted text-center">
                注册登录即表示同意 <a href="#" class="text-primary text-decoration-none">用户协议</a> 、 <a href="#"
                    class="text-primary text-decoration-none">隐私政策</a>
            </div>
        </div>
    </ContentField>
</template>

<script>
import ContentField from '../../../components/ContentField.vue'
import { useStore } from 'vuex'
import { ref } from 'vue'
import router from '../../../router/index'

export default {
    components: {
        ContentField
    },
    setup() {
        const store = useStore();
        let username = ref('');
        let password = ref('');
        let error_message = ref('');
        let showPopup = ref(false);

        const jwt_token = localStorage.getItem("jwt_token");
        if (jwt_token) {
            store.commit("updateToken", jwt_token);
            store.dispatch("getinfo", {
                success() {
                    router.push({ name: "home" });
                    store.commit("updatePullingInfo", false);
                },
                error() {
                    store.commit("updatePullingInfo", false);
                }
            });
        } else {
            store.commit("updatePullingInfo", false);
        }

        const login = () => {
            error_message.value = "";
            store.dispatch("login", {
                username: username.value,
                password: password.value,
                success() {
                    // console.log(store.state.user);
                    store.dispatch("getinfo", {
                        success() {
                            router.push({ name: 'home' });
                            console.log(store.state.user);
                        }
                    });
                    // console.log(store.state.user); //未登录
                },
                error() {
                    error_message.value = "用户名或密码错误";
                }
            })
        }

        const ClickshowPopup = () => {
            showPopup.value = true;
            setTimeout(() => {
                showPopup.value = false;
            }, 1000);
        }

        return {
            username,
            password,
            error_message,
            login,
            showPopup,
            ClickshowPopup,
        }
    }
}
</script>

<style scoped>
button {
    width: 100%;
}

div.error-message {
    color: red;
}

.svg-icons {
    display: flex;
    justify-content: center;
    gap: 50px;
}

.pop_up {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 50%;
    background-color: #FFF3CD;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.qq:hover,
.wx:hover,
.github:hover,
.twitter:hover {
    cursor: pointer;
}
</style>
