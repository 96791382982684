<template>
    <div>
        <div class="card pkindex">
            <div class="card-body">
                <span style="font-size: 200%; color: #198754;">Bot对战</span>
                <button type="button" class="btn btn-success btn-lg" @click="open_pk_home()">
                    返回
                </button>
            </div>
        </div>
    </div>

    <div class="matchground">
        <div class="row">
            <div class="col-9 container" style="margin-top: 1%;">
                <div class="alert alert-info" role="alert">
                    ❕注意事项:<br>
                    可去我的Bot内创建自己的Bot。初学代码者可<HowWirterCode><ins>参考教程</ins></HowWirterCode>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: -4%;">
            <div class="col-4">
                <div class="user-photo">
                    <img :src="$store.state.user.photo" alt="">
                </div>
                <div class="user-username">
                    {{ $store.state.user.username }}
                </div>
            </div>
            <div class="col-4">
                <div class="user-select-bot">
                    <select v-model="select_bot" class="form-select" aria-label="Default select example">
                        <option value="-1">请选择出战Bot</option>
                        <option v-for="bot in bots" :key="bot.id" :value="bot.id">
                            {{ bot.title }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-4">
                <div class="user-photo">
                    <img :src="$store.state.pk.opponent_photo" alt="">
                </div>
                <div class="user-username">
                    {{ $store.state.pk.opponent_username }}
                </div>
            </div>
            <div class="col-12" style="text-align: center; padding-top: 5vh;">
                <button type="button" class="btn btn-warning btn-lg" @click="click_match_btn">
                    {{ match_btn_info }}
                </button>
                <div v-if="showPopup" class="pop_up">
                    <div class="spinner-border text-info m-2" role="status">
                    </div>
                    <span>正在加速匹配中❤❤❤</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import $ from 'jquery';
import router from '@/router';
import HowWirterCode from '@/components/HowWirterCode.vue';

export default {
    components: {
        HowWirterCode,
    },

    setup() {
        const store = useStore();
        let match_btn_info = ref("开始匹配");
        let bots = ref([]);
        let select_bot = ref("-1");
        let showPopup = ref(false);

        const click_match_btn = () => {
            if (match_btn_info.value === "开始匹配") {
                match_btn_info.value = "取消匹配";
                // console.log(select_bot.value);
                store.state.pk.socket.send(JSON.stringify({
                    event: "start-matching",
                    bot_id: select_bot.value,
                }));

                showPopup.value = true;
                setTimeout(() => {
                    showPopup.value = false;
                }, 1000);
            } else {
                match_btn_info.value = "开始匹配";
                showPopup.value = false;
                store.state.pk.socket.send(JSON.stringify({
                    event: "stop-matching",
                }));
            }
        }

        const refresh_bots = () => {
            $.ajax({
                url: "https://www.lycoding.fun/api/user/bot/getlist/",
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    bots.value = resp;
                }
            })
        }

        refresh_bots();

        const open_pk_home = () => {
            router.push({ name: "pk_home" });
        }

        return {
            match_btn_info,
            click_match_btn,
            bots,
            select_bot,
            open_pk_home,
            showPopup,
        }
    }
}
</script>

<style scoped>
div.pkindex {
    width: 60vw;
    height: 10vh;
    margin: 10px auto;
    background-color: transparent;
    border: none;
}

div.pkindex button {
    float: right;
}

div.matchground {
    width: 60vw;
    height: 75vh;
    background-color: rgba(50, 50, 50, 0.5);
    margin: 20px auto;
}

.user-photo {
    text-align: center;
    padding-top: 10vh;
}

.user-photo>img {
    border-radius: 50%;
    width: 20vh;
}

.user-username {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: white;
    padding-top: 2vh;
}

.user-select-bot {
    padding-top: 20vh;
}

.user-select-bot>select {
    width: 60%;
    margin: 0 auto;
}

.pop_up {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 10%;
    background-color: rgba(255, 243, 205, 0.8);
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}
</style>