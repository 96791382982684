<template>
    <div class="talent" v-if="users">
        <div class="talent_div">
            <h2>达人排行榜</h2>
            <div class="all_talent">
                <div class="talent_one" v-if="user2">
                    <div class="talent_img">
                        <img :src="user2.photo" alt="头像" class="talent_header img-fluid">
                        <img src="@/assets/images/dier.png" alt="" class="talent_crown img-fluid">
                    </div>
                    <div class="talent_content">
                        <h5 class="letter">{{ user2.username }}</h5>
                        <span class="letter">我的第二！！！</span>
                    </div>
                </div>
                <div class="talent_one" v-if="user1">
                    <div class="talent_img">
                        <img :src="user1.photo" alt="头像" class="talent_header img-fluid">
                        <img src="@/assets/images/diyi.png" alt="" class="talent_crown_tow img-fluid">
                    </div>
                    <div class="talent_content">
                        <h5 class="letter">{{ user1.username }}</h5>
                        <span class="letter">嘿嘿我是第一❤</span>
                    </div>
                </div>
                <div class="talent_one" v-if="user3">
                    <div class="talent_img">
                        <img :src="user3.photo" alt="头像" class="talent_header img-fluid">
                        <img src="@/assets/images/disan.png" alt="" class="talent_crown_three img-fluid">
                    </div>
                    <div class="talent_content">
                        <h5 class="letter">{{ user3.username }}</h5>
                        <span class="letter">我是第三！！！</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ContentField>
        <table class="table table-striped table-hover" style="text-align: center;">
            <thead>
                <tr>
                    <th>排名</th>
                    <th>玩家</th>
                    <th>天梯分</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(user, index) in users" :key="user.id">
                    <td>{{ parseInt(index_page) * 10 + index - 9 }}</td>
                    <td>
                        <span v-if="parseInt(index_page) * 10 + index - 9 === 1" style="user-select: none;">🥇</span>
                        <span v-if="parseInt(index_page) * 10 + index - 9 === 2" style="user-select: none;">🥈</span>
                        <span v-if="parseInt(index_page) * 10 + index - 9 === 3" style="user-select: none;">🥉</span>
                        &nbsp;
                        <img :src="user.photo" alt="" class="record-user-photo">
                        &nbsp;
                        <span class="record-user-username">{{ user.username }}</span>
                    </td>
                    <td>{{ user.rating }}</td>
                </tr>
            </tbody>
        </table>
        <nav aria-label="...">
            <ul class="pagination" style="float: right;">
                <li class="page-item" @click="click_page(-2)">
                    <a class="page-link" href="#">前一页</a>
                </li>
                <li :class="'page-item ' + page.is_active" v-for="page in pages" :key="page.number"
                    @click="click_page(page.number)">
                    <a class="page-link" href="#">{{ page.number }}</a>
                </li>
                <li class="page-item" @click="click_page(-1)">
                    <a class="page-link" href="#">后一页</a>
                </li>
            </ul>
        </nav>
    </ContentField>
</template>

<script>
import ContentField from '../../components/ContentField.vue'
import { useStore } from 'vuex';
import { ref } from 'vue';
import $ from 'jquery';

export default {
    components: {
        ContentField
    },
    setup() {
        const store = useStore();
        let users = ref([]);
        let current_page = 1;
        let total_users = 0;
        let pages = ref([]);
        let index_page = ref([1]);

        const click_page = page => {
            if (page === -2) page = current_page - 1;
            else if (page === -1) page = current_page + 1;
            let max_pages = parseInt(Math.ceil(total_users / 10));

            if (page >= 1 && page <= max_pages) {
                pull_page(page);
            }
            index_page.value = current_page;
        }

        const udpate_pages = () => {
            let max_pages = parseInt(Math.ceil(total_users / 10));
            let new_pages = [];
            for (let i = current_page - 2; i <= current_page + 2; i++) {
                if (i >= 1 && i <= max_pages) {
                    new_pages.push({
                        number: i,
                        is_active: i === current_page ? "active" : "",
                    });
                }
            }
            pages.value = new_pages;
        }

        const pull_page = page => {
            current_page = page;
            $.ajax({
                url: "https://www.lycoding.fun/api/ranklist/getlist/",
                data: {
                    page,
                },
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    users.value = resp.users;
                    total_users = resp.users_count;
                    udpate_pages();
                },
                error(resp) {
                    console.log(resp);
                }
            })
        }

        pull_page(current_page);

        let top_users = ref([]);
        let user1 = ref([]);
        let user2 = ref([]);
        let user3 = ref([]);
        const topThreeUsers = () => {
            $.ajax({
                url: "https://www.lycoding.fun/api/ranklist/gettopuser/",
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    top_users.value = resp.users;
                    user1.value = top_users.value[0];
                    user2.value = top_users.value[1];
                    user3.value = top_users.value[2];
                },
                error(resp) {
                    console.log(resp);
                }
            })
            // return top_users.value.slice(start, end);
        }
        topThreeUsers();

        return {
            users,
            pages,
            index_page,
            user1,
            user2,
            user3,
            click_page,
        }
    }
}
</script>

<style scoped>
img.record-user-photo {
    width: 4vh;
    border-radius: 50%;
}

/* 达人排行榜 */
.talent {
    width: 100%;
    height: 200px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px 0;
}

.talent_div h2 {
    text-align: center;
    color: white;
}

.all_talent {
    margin-top: 2%;
    padding: 3px;
    display: flex;
    justify-content: space-around;
}

.all_talent .talent_one {
    width: 16%;
    height: 100px;
    background-color: white;
    border-radius: 10px;
    display: inline-block;
    box-sizing: border-box;
}

.all_talent .talent_one:nth-child(2) {
    margin-top: -20px;
}

.all_talent .talent_one .talent_img {
    display: inline-block;
    position: relative;
}

.all_talent .talent_one .talent_img .talent_header {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.all_talent .talent_one .talent_img .talent_crown {
    position: absolute;
    top: -15px;
    left: -9px;
}

.all_talent .talent_one .talent_img .talent_crown_tow {
    position: absolute;
    top: -23px;
    left: 17%;
}

.all_talent .talent_one .talent_img .talent_crown_three {
    position: absolute;
    top: -14px;
    left: 27px;
}

.all_talent .talent_one .talent_content {
    display: inline-block;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
}

.letter {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
}

.talent_one:hover {
    box-shadow: 0 -3px 23px 0 rgb(64 158 255 / 30%);
    transform: translateY(-10px);
    transition: all 0.5s;
}

h5 {
    font-size: 1.17em;
    font-weight: bold;
}
</style>
